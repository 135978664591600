import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'purple',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} 
      slidesPerGroup={1}
      breakpoints={{
      
        640: {
          slidesPerView: 2, 
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, 
          slidesPerGroup: 1,
        },
      
        1024: {
          slidesPerView: 3, 
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d1bvpoagx8hqbg.cloudfront.net/259/081b1eb7f978d799143fb202bb246fcf.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Alistair Wren</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center pt-[3%] pb-5 pr-1 pl-1'>
              As a satisfied patron of QuantumAmor, I turned to them for assistance in unlocking my phone. Their mastery and proficiency in unlocking services left a lasting impression. The team's approach and dedication to addressing my concern were extraordinary, ensuring a prompt and seamless solution. I value their assistance and professionalism, elevating my interaction with them to an exceptional level.
          </p>


          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-2'>Calgary, Canada</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQe5RYKY6rfpRmXNyGyBCMxW0eFfs_lKiU5CA&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Gideon Cromwell</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#000] text-center pt-[10%] pb-5 pr-1 pl-1'>
              I  found myself in a predicament with an iCloud-locked iPhone that I couldn't operate or access. QuantumAmor intervened and adeptly unlocked my device. Their expertise in iCloud unlocking transformed the situation, granting me complete access to my device effortlessly. I deeply appreciate their swift and efficient service.
            </p>

          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>California, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://unavatar.io/github/shrimpwagon?fallback=https%3A%2F%2Flaravel.io%2Fimages%2Flaravelio-icon-gray.svg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Leopold Magnus</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center pt-[12%] pb-5 pr-1 pl-1'>
              An individual was targeting me with scams, and I felt a pressing need to trace their whereabouts. QuantumAmor employed their expertise to conduct surveillance on the scammer's phone, collecting vital information to pinpoint and apprehend the scammer. Their effective intelligence service was priceless, guaranteeing the scammer's accountability for their deeds.
          </p>


          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Colorado, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.redd.it/14n2c3p1kcwb1.jpg'alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Persephone Calliope</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center pt-[12%] pb-5 pr-1 pl-1'>
              I encountered a situation where I was barred from my iCloud account and all my iOS devices. QuantumAmor promptly intervened, initiating a logout from all devices. Their smooth procedure assured my account's security, facilitating a trouble-free return to my iCloud account. Their proficiency in iCloud security was undeniably remarkable.
          </p>


          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[2px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-[2px]'>Bangor, Uk</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src="https://i.redd.it/lli8kxmxd2x81.jpg" alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Octavia Winslet</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center pt-[12%] pb-5 pr-1 pl-1'>
            During a hectic business trip, my iCloud account unexpectedly locked, causing a halt in accessing essential documents. QuantumAmor promptly intervened, securing a logout from all devices and enabling hassle-free account re-entry. Their swift expertise eased a critical moment, ensuring vital access to my files during my trip.
          </p>

          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-black'>South Australia, Australia</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
