import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'purple',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} 
      slidesPerGroup={1}
      breakpoints={{
      
        640: {
          slidesPerView: 2, 
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, 
          slidesPerGroup: 1,
        },
      
        1024: {
          slidesPerView: 3, 
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d1bvpoagx8hqbg.cloudfront.net/259/c755bb381212a1287777e2d599b040f2.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Caitronia Rome</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#000] text-center pt-[6%] pb-5 pr-1 pl-1'>
                Facing an unexpected suspension of my Twitter account, disrupting my online presence, I sought assistance from QuantumAmor Specialists. After an initial payment, their expertise facilitated the successful restoration of my account, reuniting me with my online community and content. I extend immense gratitude for their swift and effective resolution, reinstating my peace of mind.
            </p>



          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-2'>Detroit, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://avatars.githubusercontent.com/u/9286611?v=4' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Axton Basil</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center pt-[8%] pb-5 pr-1 pl-1'>
                Encountering an unforeseen lock on my crypto vault, which held $39,000 in Bitcoin, I reached out to QuantumAmor Specialists for help. Initiating an upfront payment for their expert services, they efficiently activated my vault, ensuring the security of all my Bitcoin. QuantumAmor Specialists' prompt resolution provided immense relief, guaranteeing the safeguarding of my funds.
        </p>


          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Atlanta, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://www.mycowichanvalleynow.com/wp-content/uploads/2017/12/59760_SEBELIUS-500.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Sophia liam</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#000] text-center pt-[2%] pb-5 pr-1 pl-1'>
                Facing a lock on my Secure Wallet, which held over $55,000 in Ethereum, due to an unauthorized payment attempt, I sought assistance from QuantumAmor Specialists. With their expertise, they efficiently restored access to my account, safeguarding my substantial Ethereum holdings. Their swift intervention brought immense relief, and I express deep gratitude for their efficient help in resolving this critical issue.
            </p>


          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Mississauga, Canada</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/media/F9fvweOWQAAw5Xl.jpg'alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Eymen Deniz</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center pt-[14%] pb-5 pr-1 pl-1'>
                Devastated when my Instagram account got disabled, I turned to QuantumAmor. Their efficient team swiftly restored my account with expertise, ensuring the security of my content. Their personalized support and prompt assistance made all the difference. Thanks to QuantumAmor Retrieval, my digital world is thriving again.
            </p>


          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[2px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-[2px]'>Chelsea, London</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src="https://photos2.spareroom.co.uk/images/flatshare/listings/large/12/12/12127036.jpg" alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Elenor</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center pt-[12%] pb-5 pr-1 pl-1'>
          I was really scared and worried when I was unexpectedly logged out of my Trust Wallet account, with $23,000 inside. Feeling helpless on how to unlock my wallet, I thankfully came across QuantumAmor. Thanks to this team of experts, they swiftly and securely unlocked my account, providing invaluable assistance in my time of need.
          </p>

          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-black'>South Australia, Australia</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
