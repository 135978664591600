import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'purple',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} 
      slidesPerGroup={1}
      breakpoints={{
      
        640: {
          slidesPerView: 2, 
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, 
          slidesPerGroup: 1,
        },
      
        1024: {
          slidesPerView: 3, 
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://cdn.mamamia.com.au/wp/wp-content/uploads/2019/03/09110623/53194210_10156278533226947_4680869991581483008_n-600x800.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Gina Fabregas</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[6%] pb-5 '>
            "In the midst of a deceptive romance, I lost $26,000 to someone I met on Tinder. QuantumAmor effortlessly restored my money, not only recovering my financial stability but also renewing my hope. Their swift and effective assistance was a lifeline during a challenging time."
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Dakota, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvbUlG590KKsg7CVUcGh4OE8Z_QfKE8VaJKA&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Jesse Pinkman</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-9 pb-10'>
            "Scammed by a woman on the POF app, promising a good time but disappearing after I paid her $12,000. Blocked and betrayed, I discovered QuantumAmor through a friend. Swiftly, they restored my money, providing efficient and invaluable assistance, sparing no time in rectifying the situation."
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Idaho, USA  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://images-na.ssl-images-amazon.com/images/S/influencer-profile-image-prod/logo/influencer-cb8245f4_1666294235155_original._CR0,209,1242,1242_._FMjpg_.jpeg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Elizabeth Edouard</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-2 pb-[12%]'>
            "Devoted to a five-month relationship, I trusted and loved him deeply. Convinced to make a substantial $62,000 investment, I discovered he was secretly receiving the money. Thankfully, QuantumAmor swiftly restored my funds, thanks to their expert team. Grateful for their prompt and effective assistance in rectifying the situation."
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Alberta, Canada</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.pinimg.com/564x/0f/ce/6b/0fce6bea79d21349d5708f7c7aeaf76c.jpg'alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Elijah Stroman</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[3%] pb-0'>
           "Encountering a girl on Reddit who claimed to be into hookups, I fixed a date and paid $800 upfront for her time. She kept asking for more, and I ended up spending $4,700, only to discover it was a scam. Desperate to recover my money, I reached out to QuantumAmor. They not only restored my funds but also brought the scammer to justice. Grateful for their prompt and effective intervention."
               </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[15px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-[15px]'>Orlando, USA </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src="https://www.tori.ng/userfiles/image/2019/mar/19/danczuk.jpg" alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Alejandra Silva</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-3 pb-5'>
            "Falling victim to a deceptive online relationship, I invested €40,000 in a fraudulent business opportunity. Heartbroken and financially devastated, QuantumAmor recovered the entire amount, offering crucial support in overcoming the emotional toll of the scam. Grateful for their expertise and commitment to justice, they were a lifeline in my recovery journey."
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-black'>Seville, Spain</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
