import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import RomanceReview from "./hooks/RomanceReview";
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import { BsCheck2All } from "react-icons/bs";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";

const Romance = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0fc3c3'
    }
  return (
   <>
   <Progress/>
     <div className="box1 overflow-hidden">
            <section class="Romance">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit 
                relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                    <motion.div className="flex xl:pt-5 pt-10"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                       <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                       <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                    </motion.div>

                    <motion.div className="h-fit"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                        <p className=" font-bold xl:text-[3rem] text-[2rem]">
                           Romance Scam Recovery
                        </p>
                    </motion.div>
                </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        
        <div className='bg-gray-200'>
            <div className='mx-auto overflow-hidden  md:max-w-7xl'>
            <div className='grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 xl:pt-[5%] pt-2'>
                    <div className='bg-reen-600 h-fit'>
                        <div className='pr-2'>
                            <p>
                            Amidst the intricate labyrinth of dating scams, where tales of love cloak vanished fortunes, assets, and ill-gotten gains, the pursuit of justice takes center stage. In this complex landscape, we emerge as a beacon of hope, steadfast in our mission to reclaim what has been wrongfully acquired. Our expertise lies in liberating funds ensnared by the clutches of deceptive schemes. Whether it's monetary restitution, property recovery, or the retrieval of stolen loans, we navigate these perilous waters with finesse. Furthermore, we possess a unique proficiency in recuperating funds entangled within the intricate networks of fabricated romance profiles, ensuring the triumph of truth over deceit.
                            </p> <br />
                            <p>
                            Place your trust in QuantumAmor for restitution. Armed with unwavering determination, we employ a diverse range of strategies and resources, dedicated to recovering what rightfully belongs to you. Together, we will unravel the complex webs of deception, restoring not only your financial security but also your faith in humanity.
                            </p>
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit xl:pt-2 pt-1'>
                        <div className='flex justify-center'>
                            <img src="https://images.ctfassets.net/u4vv676b8z52/7PmiLPMuyYxBge1282hXN/fd3c74b585972b8dad248accf5d0a247/crying-and-contacts-hero-678x446-compressor.jpg?fm=jpg&q=80" 
                            alt="" />
                        </div>
                    </div>
            </div>
            </div>
        </div>

        <div className='bg-[white] h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl text-black'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on Romance <span className='top__b'>Scam</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Loanded Money Recovery </p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Military Romance Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Money Recovery From Romance Scammer</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Properties Recovery</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hook Up Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Fake Broker Investmet Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hacked Bank Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hook Up Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hacked Bank Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-white"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        At QuantumAmor Advocates, we stand as your unwavering companion on the journey toward recovery from romance scams, committed not only to reinstating your financial equilibrium but also reigniting your trust in humanity. Our meticulous strategies are custom-designed to free funds ensnared by deceptive snares, ensuring the ascendancy of truth.
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Comprehensive Solutions:</span> From dismantling false romance accounts to reclaiming lost finances, our holistic approach empowers you to confront romance scams with unwavering confidence.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Restoring Faith:</span> Our primary mission is to revive your belief not just in financial security but also in the potential for meaningful human connections. We firmly uphold the belief that everyone deserves justice and the chance to rebuild their lives after falling victim to a scam.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Verified Success:</span> Demonstrating a history of successful recoveries, we showcase a track record of efficiently reclaiming lost funds for our clients.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Emotional Support:</span>  Recognizing the emotional challenges of recovering from a romance scam, our dedicated team offers compassionate assistance throughout the process, aiding in the restoration of your confidence and trust in the digital sphere.
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
                </div>


                <div className='mx-auto overflow-hidden  md:max-w-7xl text-[black]'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Review on Romance  <span className='border__down'>Scam</span> </span></p>
                    </div>
                    <RomanceReview/>
                </div>
                </div>

                <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>
  

        <Footer/>

   </>
  )
}

export default Romance;