import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'purple',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} 
      slidesPerGroup={1}
      breakpoints={{
      
        640: {
          slidesPerView: 2, 
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, 
          slidesPerGroup: 1,
        },
      
        1024: {
          slidesPerView: 3, 
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4IUJtpgdAgBwcurFzSSipxDQnq0_yJYA0DA&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Peregrine Ephraim</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[black] text-center py-[2%] px-2'>
                Confronting a relentless blackmailer armed with knowledge of my history, extorting money, and threatening my family plunged me into a nightmarish ordeal. Discovering QuantumAmor online became a turning point. Their immediate and effective intervention incapacitated the blackmailer, liberating me from the torment with their remarkable expertise.
        </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-2'>Dakota, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/ext_tw_video_thumb/1246158685325471745/pu/img/uGjEi64vAn7FEwpv.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>korey phillips</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center py-[3%] px-2'>
                Confronted with alarming online threats jeopardizing my family's safety through exposed sensitive information, I sought help from QuantumAmor Retrieval in a moment of desperation. Describing my predicament led to their swift and effective aid, successfully neutralizing the threats. Their adept intervention not only eliminated the online dangers but also ensured the safety and security of my family.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Idaho, USA  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/media/BmUK0OlIYAAO-Fh.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Magret Onil</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#000] text-center py-[3%] px-2'>
                Haunted by past missteps, I became a target of blackmail by an ex-partner using intimate photos to disrupt my marriage. Feeling desperate and trapped, I turned to QuantumAmor for assistance. Their involvement not only recovered my incurred expenses but also halted the ex-partner's threats, enabling me to safeguard my marriage and find peace.
            </p>

          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Alberta, Canada</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://miro.medium.com/v2/resize:fit:2400/1*q3rrH-wIeNGnGFQirw-7Aw.jpeg'alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Vivian West</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center py-[2%] px-2'>
                Caught in a haunting cycle, my own brother blackmailed me, demanding $7,000 monthly using past life events. The weight of secrecy and fear was suffocating. In a desperate attempt to maintain peace, I kept paying until QuantumAmor intervened. Their swift action not only stopped the monthly payments but also put an end to my brother's threats, granting me a chance to break free from this tormenting ordeal.
            </p>

          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[2px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-[2px]'>Orlando, USA </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src="https://i.pinimg.com/originals/cc/8a/0c/cc8a0ca9e8bb74bd3293a92862f3c9e1.jpg" alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Omar Amir</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#000] text-center py-[2%] px-2'>
                Entrapped in a web of deceit and extortion, an anonymous cybercriminal demanded £5,000 monthly to prevent the release of compromising information from my past. Seeking refuge with QuantumAmor, their remarkable intervention not only halted the relentless payments but also traced and neutralized the cybercriminal. Finally free from this terrifying grip of exploitation and uncertainty.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-black'>Seville, Spain</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
