
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import PhoneReview from "./hooks/PhoneReview";
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import { BsCheck2All } from "react-icons/bs";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const Social = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0fc3c3'
    }
  return (
   <>
   <Progress/>
     <div className="box1 overflow-hidden">
            <section class="Phone">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit 
                relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                    <motion.div className="flex xl:pt-5 pt-10"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                       <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                       <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                    </motion.div>

                    <motion.div className="h-fit"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                        <p className=" font-bold xl:text-[3rem] text-[2rem]">
                            Phone & Device Hack/Unlock
                        </p>
                    </motion.div>
                </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        
        <div className='bg-gray-200'>
            <div className='mx-auto overflow-hidden  md:max-w-7xl'>
            <div className='grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 xl:pt-[5%] pt-2'>
                    <div className='bg-reen-600 h-fit'>
                        <div className='pr-2'>
                            <p>
                            Navigating the complexities of technology can sometimes present a formidable challenge, and encountering locked devices due to potential threats can evoke immense frustration. However, fear not, as we present an optimal solution that will set your mind at ease. Witness uninterrupted access to your favored devices, regardless of carrier locks, and bid farewell to the vexation that accompanies device lockouts. Our adept team at QuantumAmor is poised to ensure a seamless unlocking process, bestowing upon you the delight of swift device utilization. Entrust us with your confidence to deliver effective and trustworthy unlocking services, empowering you to regain mastery and exploit the full potential of your prized devices.
                            </p> <br />
                            <p>
                            Within our innovative hub, we grasp the intricacies of contemporary technology, and our avant-garde methodologies guarantee a trouble-free unlocking experience. The inconvenience of device lockouts shall plague you no more. Our ingenious remedies cater to a diverse array of devices, assuring perpetual liberation. Bolstered by a multitude of contented clients and years of industry proficiency, we stand as the foremost authorities in unlocking services.
                            </p>
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit xl:pt-2 pt-1'>
                        <div className='flex justify-center'>
                            <img src="https://www.certosoftware.com/app/uploads/2020/07/Can-someone-hack-my-cell-phone-with-just-my-number-featured2.jpg" 
                            alt="" className='rounded-md'/>
                        </div>
                    </div>
            </div>
            </div>
        </div>

        <div className='bg-[#white] h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl text-black'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on Device <span className='top__b'>Unlock/Hack</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iMac, Mac Book & iPad Unlock/Hack</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iCloud Unlock</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Phone Tracking</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Android Hack/Unlock</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iOS Hack/Unlock</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Laptop & Phone Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Laptop & Phone Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iOS Hack/Unlock</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-white"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        Unlocking the complete potential of your phone or device is essential for optimal use. QuantumAmor presents a streamlined and reliable solution, setting us apart in the realm of phone/device unlocking. Here's why choosing us for your unlocking and bypassing needs is a wise decision.
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Expertise and Skill:</span>  Our seasoned team possesses extensive expertise and practical experience in the intricate realm of phone/device unlocking. With years of industry insight, we adeptly navigate complexities.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Proven Success:</span> With a history of successful solutions, we showcase a legacy of efficiently retrieving lost funds for clients.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Peace of Mind:</span> Unlocking or bypassing your device can notably augment its functionality, opening new possibilities. We assure the peace of mind that emerges from entrusting your device to capable hands, ensuring the utmost confidentiality for your data.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Reputable Standing:</span> We've garnered a respected reputation in the field, supported by a satisfied customer base affirming our reliability and professionalism. Our track record underscores our commitment to excellence.
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
            </div>



                <div className='mx-auto overflow-hidden  md:max-w-7xl text-[white]'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Review on Social Media  <span className='top__b'>Hack/Recovery</span> </span></p>
                    </div>
                    <PhoneReview/>
                </div>
                </div>


                <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>
  

        <Footer/>

   </>
  )
}

export default Social;