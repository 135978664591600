import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'purple',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqYZefrAoRCP-EEPdTNENW_bZsL41VQHrR_w&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'> Walt Flynn</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[13%] pb-5 '>
            "I was scammed $19,000 from a fake investment website. After months of trying to recover my money, I turned to QuantumAmor. Their expert team quickly restored my funds. Grateful for their efficient help. Trustworthy and effective – QuantumAmor Retrieval is the real deal!"
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Washington, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://preview.redd.it/my-take-on-the-clean-girl-glass-skin-look-magic-is-in-the-v0-hrd34hkl67ja1.jpg?width=640&crop=smart&auto=webp&s=7563a5e0a36cedf5322219107e1d68f1f6c51884' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Elena saltzman</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-5 pb-5'>
            "For months, I've been in an online relationship with a man on Facebook. I paid him around $35,000 in bitcoin, believing he was building our home in Tucson, Arizona. It turned out to be a scam; he blocked me and took all my money. Thankfully, I was referred to QuantumAmor. Their expert team restored my funds. Grateful for their expertise and swift action in recovering my hard-earned money."
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Arizona, USA  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.pinimg.com/736x/a9/be/35/a9be3551255a34385a08eb9ef0e03ed8.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>MacGee Dylan</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-2 pb-[12%]'>
                "My social media account was disabled, and in the process of trying to recover it, I encountered a fake recovery firm. They promised to restore my account but ended up taking $9,000 from me. Thankfully, I came across a recommendation for QuantumAmor Retrieval. They efficiently restored my money without wasting any time. Grateful for this trustworthy team."
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Florida, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://www.cuteonly.com/photos/34/13/2k/beautiful-russian-woman-maria-705316-1.jpg'alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Feyona Davis</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[2%] pb-0'>
            QuantumAmor saved me from a nightmare when I mistakenly sent my bitcoin to the wrong address. Panicked and frustrated, I thought my funds were lost forever. However, QuantumAmor expert team stepped in and successfully recovered the misplaced bitcoin. Their swift action and technical expertise turned what seemed like a dire situation into a sigh of relief. I can't thank them enough for their remarkable service.
               </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Sydney, Australia </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmJoXIGECz1b0XT5fioRW7VbkY58hZyRDSYQ&usqp=CAU" alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Damian Stones</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-3 pb-5'>
            "I fell victim to a sophisticated online scam that left me without a significant amount of bitcoin. Distraught and unsure of where to turn, I found QuantumAmor through a friend's recommendation. Through their advanced recovery methods, they managed to retrieve the lost bitcoin, providing me with a second chance. QuantumAmor is a lifesaver for those caught in the web of cryptocurrency scams."
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-black'>Ontario, Canada</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
