import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  return (
    <Box style={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        sx={{
          color: '#5045E4',
          '& .MuiCircularProgress-determinateCircle': {
            stroke: '#5045E4',
          },
        }}
        variant="determinate"
        {...props}
      />
      <Box
        style={{
          top: 1,
          left: 1,
          bottom: 1,
          right: 1,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div variant="caption" className="!text-[black] !text-[1rem]">
          {`${Math.round(props.value)}%`}
        </div>
      </Box>
    </Box>
  );
}

export default function CircularWithValueLabel() {
  const [progress, setProgress] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const circularProgressRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      });
    });

    const currentProgressRef = circularProgressRef.current;

    if (currentProgressRef) {
      observer.observe(currentProgressRef);
    }

    return () => {
      if (currentProgressRef) {
        observer.unobserve(currentProgressRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      if (progress < 100) {
        const timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress >= 85 ? 85 : prevProgress + 1));
        }, 40);

        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [isInView, progress]);

  return (
    <div ref={circularProgressRef}>
       <CircularProgressWithLabel value={progress} className="!w-[60px] !h-[60px] xl:!w-[100px] xl:!h-[100px]
  lg:!w-[100px] lg:!h-[100px] md:!w-[100px] md:!h-[100px] sm:!w-[100px] sm:!h-[100px]"/>
    </div>
  );
}
