import React,{useState} from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { useRef, useEffect,} from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import { TbShieldCheck } from "react-icons/tb";
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import Reviews  from './hooks/Review';
import { Link } from 'react-router-dom';
import Footer from './hooks/Footer';
import Progress from "./hooks/Progress";

const Home = () => {
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </Typography>
        );
      }
    
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
    
      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }
    
      const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
        bgcolor: '#5045E4',
        
      };
    
      const fabWhiteStyle = {
        color: 'common.white',
        bgcolor: '#5045E4',
        '&:hover': {
          color: '#5045E4', // Change the color on hover
          bgcolor: 'transparent',
        },
      };
    
      const [value, setValue] = React.useState(0);
      const isMobile = useMediaQuery('(max-width: 600px)');
    
      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
      const transitionDuration = 500;
    
      const fabs = [
        {
          color: 'primary',
          sx: fabStyle,
          icon: <StarIcon />, // Icon for Excellence
          label: 'Excellence',
        },
        {
          color: 'secondary',
          sx: fabStyle,
          icon: <SecurityIcon />, // Icon for Integrity
          label: 'Integrity',
        },
        {
          color: 'inherit',
          sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
          icon: <GroupIcon />, // Icon for Teamwork
          label: 'Teamwork',
        },
      ];


      const Accordion = ({ title, content }) => {
        const [isOpen, setIsOpen] = useState(false);
      
        return (
          <div className="border rounded shadow mb-4">
            <div
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-200"
              onClick={() => setIsOpen(!isOpen)}
            >
              <h2 className="text-lg font-semibold">{title}</h2>
              <svg
                className={`w-6 h-6 transition-transform duration-300 transform text-[#5045E4] ${
                  isOpen ? 'rotate-45' : ''
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={isOpen ? 'M6 18L18 6' : 'M6 18L18 6M6 6l12 12'}
                />
              </svg>
            </div>
            {isOpen && (
              <div className="p-4 bg-white">
                <p>{content}</p> <br />
              </div>
            )}
          </div>
        );
      };
    

  return (
  <>
  <Progress/>
  <div className="box1 overflow-hidden">
            <section class="herox">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="revoke.png" alt="" className="w-[40%]"/> */}
                        </div>
                </motion.div>
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>Your Reliable Asset Recovery Partner!</p>
                                </motion.div>

                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Expert Team & Cutting-Edge<br/>Technology for Swift Retrieval</p>
                                </motion.div>

                                <motion.div className='pt-2'
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>
                                    Specializing in expert asset recovery services for both individuals and businesses, 
                                    we assure you of swift and seamless asset retrieval.
                                    </p>
                                </motion.div>

                                <div className='bg-[reen] h-20 flex gap-1 mr-1 mt-5'>
                                   <a href="https://quantumamor.com/signup/" target='_blank'rel="noreferrer"><button className='get__one'>Sign-up <i class="ri-arrow-right-line"></i></button></a>
                                    <a href="/Contact"><button className='get__two'>Report Fraud <i class="ri-arrow-right-line"></i></button></a>
                                </div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    <div className='mx-auto overflow-hidden  md:max-w-7xl'>
        <div className='xl:ml-2 ml-5'>
            <p className='font-bold text-[25px] pt-10'>
                OUR TOP <span className='Policy_span'>POLICY</span> 
            </p>
        </div>
        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[3%]'>
                <motion.div className='bg-geen-600 h-fit policy___border'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='ml-5 mt-5'>
                        <div className='bg-[#5045E4] h-fit w-fit px-3 py-3 rounded-md'>
                            <div>
                                <img src="return.png" alt=""  className='w-[60px]'/>
                            </div>
                        </div>
                    </div>

                    <div className='ml-5 pt-5'>
                        <p className='font-bold text-[20px]'>100% Refund Policy</p>
                    </div>

                    <div className='ml-5 pt-4 pr-2 pb-5'>
                        <p>
                        Leveraging a diverse range of skills within our reach, we uphold strong assurance in our exceptional track record, reducing the probability of refund requests. Nonetheless, if you deem our services less than satisfactory, rest assured that we facilitate a swift and straightforward refund process. 
                        </p>
                    </div>
                </motion.div>
                <motion.div className='bg-geen-600 h-fit policy___border'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='ml-5 mt-5'>
                        <div className='bg-[#5045E4] h-fit w-fit px-3 py-3 rounded-md'>
                            <div>
                                <img src="phoenix.png" alt=""  className='w-[60px]'/>
                            </div>
                        </div>
                    </div>

                    <div className='ml-5 pt-5'>
                        <p className='font-bold text-[20px]'>Resilience</p>
                    </div>

                    <div className='ml-5 pt-4 pr-2 pb-10'>
                        <p>
                        Within our recovery agency, we embody the fundamental trait of resilience. Our dedication to supporting clients in challenging situations remains steadfast. With a diverse set of skills, we sustain an exceptional success rate, diminishing the necessity for reimbursements.
                        </p>
                    </div>
                </motion.div>
                <motion.div className='bg-geen-600 h-fit policy___border'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.6, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='ml-5 mt-5'>
                        <div className='bg-[#5045E4] h-fit w-fit px-3 py-3 rounded-md'>
                            <div>
                                <img src="compliant.png" alt=""  className='w-[60px]'/>
                            </div>
                        </div>
                    </div>

                    <div className='ml-5 pt-5'>
                        <p className='font-bold text-[20px]'>Privacy Policy</p>
                    </div>

                    <div className='ml-5 pt-4 pr-2 pb-5'>
                        <p>
                        Detailing our commitment to safeguarding clients' personal and financial information, our policy adheres to all pertinent data protection regulations. Committed to the highest standards of security, we go the extra mile to ensure the confidentiality and integrity of your sensitive data.
                        </p>
                    </div>
                </motion.div>
        </div>

      
    </div>

   
     <div className='bg-[#fefefe]'>
        <div className='mx-auto overflow-hidden md:max-w-7xl'>
        <div className='xl:ml-2 ml-5'>
            <p className='font-bold text-[25px] pt-10'>
                WAYS WE <span className='Policy_span'>HELP</span> 
            </p>
        </div>
        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 xl:pt-[3%] pt-0'>
            <div className='b-green-600 h-fit'>
                <div>
                    <p>
                    We tirelessly raise awareness, expose scammers, and recover frozen assets for creditors. Through liquidations and 
                    international freezing orders, we regain control of funds held by banks. Our unwavering commitment ensures the 
                    defeat of scammers.
                    </p>
                </div>
                
                <motion.div className='pt-5 flex'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                    <div className='pt-2'> 
                    <TbShieldCheck size={30} color='#5045E4'/>
                    </div>
                    <div className='pl-5 pr-3'>
                        <p>Exposing fraud, recovering assets, and ensuring a swift resolution for your case.</p>
                    </div>
                </motion.div>
                <motion.div className='pt-5 flex'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='pt-2'> 
                    <TbShieldCheck size={30} color='#5045E4'/>
                    </div>
                    <div className='pl-5 pr-3'>
                        <p>Rapidly addressing fraud, recovering assets, and providing a quick and straightforward case review.</p>
                    </div>
                </motion.div>
                <motion.div className='pt-5 flex'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.5, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='pt-2'> 
                    <TbShieldCheck size={30} color='#5045E4'/>
                    </div>
                    <div className='pl-5 pr-3'>
                        <p>Swiftly exposing fraud, recovering assets, and ensuring a prompt resolution in your case.</p>
                    </div>
                </motion.div>
                <motion.div className='pt-5 flex'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.6, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='pt-2'> 
                    <TbShieldCheck size={30} color='#5045E4'/>
                    </div>
                    <div className='pl-5 pr-3'>
                        <p>Swiftly tackling fraud, recovering assets, and delivering a rapid resolution tailored to your case</p>
                    </div>
                </motion.div>
                <motion.div className='pt-5 flex'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.7, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='pt-2'> 
                    <TbShieldCheck size={30} color='#5045E4'/>
                    </div>
                    <div className='pl-5 pr-3'>
                        <p>Efficiently addressing fraud, securing asset recovery, and ensuring a prompt case review to meet your needs.</p>
                    </div>
                </motion.div>
                
            </div>
            <div className='bg-reen-600 h-fit'>
                <div>
                    <img src="https://www.telstra.com.au/content/dam/shared-component-assets/tecom/security/security-home/secure-business/cyber-detection-and-response-endpoint/STK_adobe_493463050_headerDesktopTall_692x500.jpeg" 
                    alt="" className='rounded-lg'/>
                </div>
            </div>
        </div>
        </div>
    </div> 

     <section class="Numbers">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.2, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#5045E4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="currency.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            $<AnimatedNumbers value={30}/>m+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Money Restored
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.4, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#5045E4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="rating-stars.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={11000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Satisfied Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.6, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#5045E4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="trusto.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={10000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Trusted 
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.7, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#5045E4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="data-security.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={7000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Protected Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                  </div>
               </div>
            </div>
        </section> 

        <div className='mx-auto overflow-hidden  md:max-w-7xl'>
        <div className='xl:ml-2 ml-5'>
            <p className='font-bold text-[25px] pt-10'>
                OUR TOP <span className='Policy_span'>SERVICE'S</span> 
            </p>
        </div>
            <div className='grid mt-10 xl:mt-0 ml-1 mr-1 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-3 pb-10 pt-[5%]'>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.2, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#5045E4]">
                                <img src="cryptocurrency.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                    Crypto Currency Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-10 pt-3">
                            <p>
                            Have you ever felt the frustration of lost funds or cryptocurrency slipping through your grasp? Worry not, 
                            for we're here to guide you through the intricate path of their recovery. Rest assured, with our expertise, 
                            you can regain access to your locked-out wallet. Your journey is made smoother with the adept assistance, 
                            led by QuantumAmor and their dedicated team of professionals.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Crypto" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#5045E4]">
                                <img src="hearts.png" alt="" className="!w-[80px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                    Romance Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Assisting Those Affected by Romance Scams, we're committed to helping you overcome the distress caused by these 
                            deceitful actions. With our focused experts, we're determined to rebuild your confidence and guide you through 
                            the recovery of your lost assets. Trust us to lead you towards recovery with unwavering commitment. we guide 
                            you in overcoming distress and guide you through the recovery of your lost assets.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Romance" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.5, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#5045E4]">
                                <img src="blackmail.png" alt="" className="!w-[124px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Blackmail / Extortion Help
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-10 pt-3">
                            <p>
                            Empowering and Advocating for Victims of Online Blackmail, Sextortion, and Threats. Our dedicated mission is to 
                            provide unwavering support and compassionate assistance to individuals who have unfortunately encountered these 
                            distressing experiences. We steadfastly stand by their side, offering expert guidance to those who have fallen 
                            prey to online threats and malicious intent.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Blackmail" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.6, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#5045E4]">
                                <img src="bullhorn.png" alt="" className="!w-[80px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Social Media Spy/Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Empowering Your Digital Connections: Social accounts act as crucial links to numerous achievements and cherished 
                            connections. Recognizing their importance, we stand unwavering in our dedication to surpass expectations, leaving 
                            no stone unturned in ensuring your swift account recovery. Trust in our unwavering expertise and commitment to 
                            promptly reinstate your access
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Social" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.7, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#5045E4]">
                                <img src="hacker.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Phone Hack/unlock
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            QuantumAmor, Comprising a team of esteemed specialists in phone unlocking, rest assured, no detail 
                            escapes our scrutiny. Our meticulous expertise and unwavering commitment guarantee thoroughness in each phase,
                            delivering definitive solutions tailored to your requirements. Place your trust in us for an unparalleled, 
                            hassle-free experience & Our mission is to safeguard your digital assets.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/PhoneUnlock" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.8, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#5045E4]">
                                <img src="password.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Account Unlock / Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Unlock the potential to regain access to your locked accounts with our tailored tools. Experience the 
                            convenience of our solutions designed to overcome account lockouts effortlessly.
                            At QuantumAmor, we specialize in swift account recovery, providing you with the means to 
                            regain control and peace of mind for your digital assets.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5 mt-5">
                            <a href="/AccountUnlock" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
            </div>
        </div>

        <div className='bg-[#efefef]'>
        
             <div className="mx-auto overflow-hidden  md:max-w-7xl">
             <div className='xl:ml-2 ml-5'>
            <p className='font-bold text-[25px] pt-10'>
                OUR  CORE <span className='Policy_span'>VALUES</span> 
            </p>
        </div>
            <div className="grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                <div className="bg-green-00 h-fit">
                    <div className="flex justify-center">
                        <img src="https://www.gea.com/en/binaries/header_tcm11-110344.jpeg" alt=""
                        className="rounded-md"/>
                    </div>
                </div>
                <div className="bg-green-00 h-fit">
                <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
          position: 'relative',
          minHeight: 200,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
           value={value}
           onChange={handleChange}
           indicatorColor="#5045E4" // Change this to "#29d090"
           textColor="#5045E4"
           variant="fullWidth"
           aria-label="action tabs example"
           sx={{ "& .MuiTabs-indicator": { backgroundColor: "#5045E4" } }} 
          >
            <Tab label="Expertise" {...a11yProps(0)} />
            <Tab label="Ethical" {...a11yProps(1)} />
            <Tab label="Resilience" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <p className='font-bold'>Expertise and Experience:</p>
          </div>
          <div className='pt-3'>
          Through ongoing enhancement, we labor diligently and persistently to exceed your expectations. Our steadfast dedication ensures that we won't cease our efforts until we've attained excellence and fulfilled the task to your highest satisfaction.
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <p className='font-bold'>Unwavering Ethical Commitment</p>
          </div>
          <div className='pt-3'>
          We maintain the utmost standards of trustworthiness and ethical conduct. Our unwavering commitment to integrity is the foundation of our service, guaranteeing your complete confidence in our work. We are devoted to preserving your trust continuously, recognizing that your peace of mind is of paramount importance.

          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <p className='font-bold'>Unified Resilience</p>
          </div>
          <div className='pt-3'>
          Our dynamic collaboration thrives on collective determination. We join forces as a committed team, confronting challenges directly and achieving results together. With steadfast determination, we seamlessly work towards realizing your goals at every step of the process.
          </div>
        </TabPanel>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </Box>
    </Container>
                    <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Expertise</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
               </div>
                </div>
            </div>

       
             </div>
      </div>

    
        <div className='mx-auto overflow-hidden md:max-w-7xl'>
        <div className="mx-auto overflow-hidden  md:max-w-7xl">
             <div className='xl:ml-2 ml-5'>
            <p className='font-bold text-[25px] pt-10'>
                OUR  TOP <span className='Policy_span'>TESTIMONIES</span> 
            </p>
        </div>
        </div>
            <Reviews/>
        </div>


        <div className='mx-auto overflow-hidden md:max-w-7xl'>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 pb-10 pt-[3%]'>
                            <div className='bg-green-00 h-fit'>
                                <Accordion
                                    title="100% Refund Policy"
                                    content="QuantumAmor is our unwavering commitment to delivering superior results and 
                                    meeting the high expectations of our valued clients. While we have full confidence in our ability 
                                    to accomplish the given tasks, we acknowledge that unforeseen circumstances may occasionally arise, 
                                    potentially impacting our ability to honor our commitments. In such cases, if we find ourselves 
                                    unable to fulfill a task, we stand by our commitment to customer satisfaction by offering a refund, 
                                    ensuring trust in our brand
                                    At the heart of our operations lie principles of transparency and fairness in all our dealings. Our 
                                    terms and conditions are thoughtfully designed to provide clarity and protection for both our clients 
                                    and our team. Our dedicated focus remains on delivering exceptional service and value to our clients. 
                                    Continuously, we strive to improve our processes, consistently surpassing expectations.
                                    "
                                />
                                <Accordion
                                   title="How does your recovery firm operate?"
                                    content="Our firm, specializing in scam recovery, is steadfast in its commitment to aiding victims of 
                                    fraudulent schemes throughout the intricate process of reclaiming their lost funds. Employing a 
                                    multi-faceted approach, we merge specialized expertise, cutting-edge technology, and legal resources 
                                    to offer the utmost support to our clients.
                                    Central to our operations is a team comprising seasoned professionals with a niche focus on the complex 
                                    realm of scam recovery. These adept experts harness a blend of investigative methodologies and financial
                                     expertise to meticulously unravel the intricate web of transactions associated with the scam. 
                                     Through exhaustive tracking of the financial trail, we meticulously pinpoint and segregate the assets 
                                     rightfully belonging to you.
                                    "
                                />
                            </div>
                            <div className='bg-green600 h-fit'>
                                <Accordion 
                                    title="Can I Trust This Firm?" 
                                    content="We acknowledge the pivotal importance of trust and authority when it comes to our clients 
                                    feeling secure and confident in sharing sensitive financial information with our team. We are fully 
                                    aware that the decision to entrust us with such critical information necessitates careful 
                                    consideration, a responsibility we take with the utmost seriousness.
                                    We firmly believe that trust is not simply given, but earned through a continuous display of 
                                    consistent and dependable performance. Our commitment lies in surpassing our clients' expectations 
                                    in every facet of our work, striving to foster an environment of reliability and excellence."
                                    
                                    />
                                <Accordion 
                                    title="How Long dose the process take?" 
                                    content="The process of recovering lost Cryptocurrency spans a variable timeline, fluctuating between 
                                    durations as short as one hour and potentially extending to two months or more. This duration may seem 
                                    extensive due to our comprehensive initial search conducted within the first week of onboarding a 
                                    client. After this exhaustive search, we retain the encrypted private backup and employ password 
                                    guesses to perpetuate testing, identifying new recovery vectors, and enhancing our hardware 
                                    capabilities.
                                    Respecting the privacy and security of our clients, we maintain client information unless expressly 
                                    requested for deletion. Our commitment to safeguarding client privacy remains unwavering, adhering 
                                    to strict protocols in data management
                                    " 
                                />
                            </div>
                        </div>
                    </div>

                    <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>
  

    <Footer/>
  
  </>
  )
}

export default Home