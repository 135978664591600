import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useRef, useEffect,} from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; 
import SecurityIcon from '@mui/icons-material/Security';
import GroupIcon from '@mui/icons-material/Group'; 
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";

const About = () => {

    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </Typography>
        );
      }
    
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
    
      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }
    
      const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
        bgcolor: '#5045E4',
        
      };
    
      const fabWhiteStyle = {
        color: 'common.white',
        bgcolor: '#5045E4',
        '&:hover': {
          color: '#5045E4', // Change the color on hover
          bgcolor: 'transparent',
        },
      };
    
      const [value, setValue] = React.useState(0);
      const isMobile = useMediaQuery('(max-width: 600px)');
    
      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
      const transitionDuration = 500;
    
      const fabs = [
        {
          color: 'primary',
          sx: fabStyle,
          icon: <StarIcon />, // Icon for Excellence
          label: 'Excellence',
        },
        {
          color: 'secondary',
          sx: fabStyle,
          icon: <SecurityIcon />, // Icon for Integrity
          label: 'Integrity',
        },
        {
          color: 'inherit',
          sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
          icon: <GroupIcon />, // Icon for Teamwork
          label: 'Teamwork',
        },
      ];
  return (
   <>
   <Progress/>
   <div className="box1 overflow-hidden">
            <section class="About">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit 
                relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                    <motion.div className="flex xl:pt-5 pt-10"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                       <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                       <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                    </motion.div>

                    <motion.div className="h-fit"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                        <p className=" font-bold xl:text-[3rem] text-[2rem]">
                          About QuantumAmor
                        </p>
                    </motion.div>
                </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    <div className='bg-[#efefef] h-fit'>
        <div className='mx-auto md:max-w-7xl'>
            <div className='grid pt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 xl:pt-[5%] '>
                <div className='bg-reen-600 h-fit'>
                     <p>
                     At QuantumAmor, we proudly stand in partnership with the FBI, committed to shielding you from the clutches of fraudsters and unwaveringly restoring the fruits of your hard work. Through the synergy of our specialized knowledge and cutting-edge technological capabilities, we present an unparalleled tapestry of serenity by reclaiming financial assets ensnared within the grasp of fraudulent schemes. Our collaboration with the FBI amplifies our commitment to combating scams, ensuring a fortified defense against cyber threats.
                     </p>

                     <p> <br />
                     Our team of seasoned experts comprehends the profound consequences of falling victim to scams, approaching each case with the precision of a master artisan. Meticulous in our discretion, we craft tailored solutions attuned to the unique rhythm of your circumstances. Whether entrapped in the labyrinth of online phishing, ensnared by investment fraud, or deceived by the allure of romance turned treacherous, QuantumAmor stands ready to tackle even the most intricate of entanglements.
                     </p> <br />

                     <p>
                     With a history of successful retrievals and a vast network of esteemed clients worldwide, we have solidified our reputation as an unassailable fortress of recovery. Simultaneously, as we diligently work to restore your assets, we send an unequivocal message to those who deceive: their schemes will face swift and precise retribution. Place your unwavering trust in QuantumAmor to redefine the landscape of romance scam recovery and illuminate the path to financial redemption in collaboration with the FBI.
                     </p>
                </div>
                <div className='bg-reen-600 h-fit'>
                     <div>
                        <img src="https://phantom-marca.unidadeditorial.es/0b6ab5fcd2bca14dea030f51fdf2390e/resize/1200/f/webp/assets/multimedia/imagenes/2022/10/23/16665064157036.jpg"
                         alt="" className='rounded-md'/>
                     </div>
                </div>
            </div> 
        </div>
    </div>

    <section class="Numbers">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.2, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#5045E4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="currency.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            $<AnimatedNumbers value={30}/>m+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Money Restored
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.4, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#5045E4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="rating-stars.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={11000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Satisfied Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.6, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#5045E4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="trusto.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={10000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Trusted 
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.7, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#5045E4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="review.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={13}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                             Years of Experience
                            </p>
                          </div>
                        </div>
                      </motion.div>
                  </div>
               </div>
            </div>
        </section> 

        <div className='bg-[#efefef]'>
        
        <div className="mx-auto overflow-hidden  md:max-w-7xl">
        <div className='xl:ml-2 ml-5'>
       <p className='font-bold text-[25px] pt-10'>
           WHY CHOOSE <span className='Policy_span'>US</span> 
       </p>
   </div>
       <div className="grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
           <div className="bg-green-00 h-fit">
               <div className="flex justify-center">
                   <img src="https://around.uoregon.edu/sites/default/files/styles/landscape_xl/public/2023-09/cybersecurity-adobestock.jpeg?itok=ZnFKmyjc" alt=""
                   className="rounded-md"/>
               </div>
           </div>
           <div className="bg-green-00 h-fit">
           <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
 <CssBaseline />
 <Box
   sx={{
     bgcolor: 'background.paper',
     width: '100%',
     position: 'relative',
     minHeight: 200,
     marginLeft: 0,
     marginRight: 0,
   }}
 >
   <AppBar position="static" color="default">
     <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="#5045E4" // Change this to "#29d090"
      textColor="#5045E4"
      variant="fullWidth"
      aria-label="action tabs example"
      sx={{ "& .MuiTabs-indicator": { backgroundColor: "#5045E4" } }} 
     >
       <Tab label="FBI" {...a11yProps(0)} />
       <Tab label="Approach" {...a11yProps(1)} />
       <Tab label="Network" {...a11yProps(2)} />
     </Tabs>
   </AppBar>
   <TabPanel value={value} index={0}>
     <div>
       <p className='font-bold'>Expertise and Partnership with the FBI:</p>
     </div>
     <div className='pt-3'>
     At QuantumAmor, our team comprises seasoned experts with a profound understanding of the consequences of falling victim to scams. Our collaboration with the FBI strengthens our commitment to combatting fraudulent schemes. This partnership ensures that clients benefit from cutting-edge technological capabilities and specialized knowledge, creating a formidable defense against cyber threats.
     </div>
   </TabPanel>
   <TabPanel value={value} index={1}>
     <div>
       <p className='font-bold'>Tailored Solutions and Meticulous Approach:</p>
     </div>
     <div className='pt-3'>
     We recognize that each case is unique, and the consequences of financial scams vary. Our experts approach each situation with the precision of a master artisan, offering tailored solutions attuned to the specific circumstances of our clients. Whether it's online phishing, investment fraud, or romance scams, QuantumAmor employs a meticulous and discreet approach to disentangle even the most intricate entanglements.
     </div>
   </TabPanel>
   <TabPanel value={value} index={2}>
     <div>
       <p className='font-bold'>Proven Track Record and Global Network:</p>
     </div>
     <div className='pt-3'>
     With a history of successful retrievals and a vast network of esteemed clients worldwide, QuantumAmor has solidified its reputation as an unassailable fortress of recovery. Our track record demonstrates our ability to restore financial assets swiftly and effectively. Choosing QuantumAmor means placing trust in a company with a global presence, ensuring that your case is handled with the utmost professionalism and expertise.
     </div>
   </TabPanel>
   {fabs.map((fab, index) => (
     <Zoom
       key={fab.color}
       in={value === index}
       timeout={transitionDuration}
       style={{
         transitionDelay: `${value === index ? transitionDuration : 0}ms`,
       }}
       unmountOnExit
     >
       <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
         {fab.icon}
       </Fab>
     </Zoom>
   ))}
 </Box>
</Container>
               <div className='bg-[ink] h-fit flex mt-8'>
                       <div className='bg-[range] h-fit w-full'>
                           <div className='flex justify-center'>
                               <Circular/>
                           </div>
                           <div className='flex justify-center'>
                               <p className='text-center font-bold'>Expertise</p>
                           </div>
                       </div>
                       <div className='bg-[urple] h-fit w-full'>
                           <div className='flex justify-center'>
                               <Circularx/>
                           </div>
                           <div className='flex justify-center'>
                               <p className='text-center font-bold'>Security</p>
                           </div>
                       </div>
                       <div className='bg-[ray] h-fit w-full'>
                           <div className='flex justify-center'>
                               <Circularxx/>
                           </div>
                           <div className='flex justify-center'>
                               <p className='text-center font-bold'>Trust</p>
                           </div>
                       </div>
          </div>
           </div>
       </div>

  
        </div>
 </div>

       <div>
        <Footer/>
       </div>
   
   
   </>
  )
}

export default About;