import React, { useState } from "react";
import { RiMenuLine } from "react-icons/ri";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
// import Popover from "./hooks/Popover";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
 

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };
 
  return (
    <nav className="bg-transparent z-40  w-full p-2 xl:p-4 pl-10 pr-10 relative">
      <div className="flex items-center justify-between ">
        <div className="flex items-center">
          <button className="text-white mr-4 md:hidden" onClick={toggleSidebar}>
            {isSidebarOpen ? <FiX size={24} className="!text-[white] hidden" /> : <RiMenuLine size={24} className="!text-[#5045E4] relative" />}
          </button>
         <div>
         <img src="qt.png" alt="" className="w-[170px]"/>
         </div>
        </div>

        <ul className="hidden md:flex space-x-4 border__down">
          <Link to="/"><li className="text-[white]">Home</li></Link>
          {/* <Popover/> */}
          <div className="flex">
              <button
                onClick={toggleServices}
                className="text-[white]"
              >
                Services
              </button> <i class="ri-arrow-down-s-line text-[#5045E4] cursor-pointer" onClick={toggleServices}></i>
            </div>
            {isServicesOpen && (
              <div className=" fixed z-50  mt-5 bg-white border border-gray-200 rounded-md shadow-lg w-[13rem] below__x">
                <div className="flex justify-end">
                <i class="ri-close-line text-black pb- cursor-pointer text-[1.3rem]" onClick={toggleServices}></i>
                </div>
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu ">
                  <Link to="/Blackmail" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Blackmail/Extortion
                  </Link>
                  <Link to="/Crypto" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Crypto Recovery
                  </Link>
                  <Link to="/AccountUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Account Activation
                  </Link>
                  <Link to="/Romance" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Romance Scam Recovery
                  </Link>
                  <Link to="/Social" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Social Media Hack/Recovery
                  </Link>
                  <Link to="/PhoneUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Phone/Device Unlock
                  </Link>
                </div>
              </div>
            )}
           
            
          <Link to="/About"><li className="text-[white]">About</li></Link>
          <a href="#Review"><li className="text-[white]">Reviews</li></a>
          <Link to="/Contact"><li className="text-[white]">Contact</li></Link>
          <a href="#FAQ"><li className="text-[white]">FAQ</li></a>
        </ul>
      </div>

      {/* Dark Background Overlay */}
      <div
        className={`${
            isSidebarOpen ? "block" : "hidden"
          } md:hidden fixed top-0 left-0 h-screen w-full bg-opacity-70 bg-black`}
        onClick={toggleSidebar}
      ></div>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 z-40 right-0  w-64 bg-[#5045E4] p-4 transform ${
            isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
          } transition-transform duration-300 ease-in-out`}
      >
        <div className="p-4 text-[white] ">
          <h2 className="text-xl font-bold">Sidebar Content</h2>
          <ul className="mt-4">
            <Link to="/"><li className="py-2 hover:bg-[white] hover:text-[#5045E4]" onClick={toggleSidebar}>Home</li></Link>
            <div className="flex">
              <button
                onClick={toggleServices}
                className="text-[white]"
              >
                Services
              </button> <i class="ri-arrow-down-s-line text-[#fefefe] cursor-pointer" onClick={toggleServices}></i>
            </div>
            {isServicesOpen && (
              <div className="absolute z-10 mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-[13rem] below__x">
                <div className="flex justify-end">
                <i class="ri-close-line text-black pb- cursor-pointer text-[1.3rem]" onClick={toggleServices}></i>
                </div>
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu ">
                <Link to="/Blackmail" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Blackmail/Extortion
                  </Link>
                  <Link to="/Crypto" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Crypto Recovery
                  </Link>
                  <Link to="/AccountUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  Account Activation
                  </Link>
                  <Link to="/Romance" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Romance Scam Recovery
                  </Link>
                  <Link to="/Social" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Social Media Hack/Recovery
                  </Link>
                  <Link to="/PhoneUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Phone/Device Unlock
                  </Link>
                </div>
              </div>
            )}
            <Link to="/About"><li className="py-2 hover:bg-[white] hover:text-[#0779e4]" onClick={toggleSidebar}>About</li></Link>
            <a href="#Review"><li className="py-2 hover:bg-[white] hover:text-[#0779e4]" onClick={toggleSidebar}>Reviews</li></a>
            <Link to="/Contact"><li className="py-2 hover:bg-[white] hover:text-[#0779e4]" onClick={toggleSidebar}>Contact</li></Link>
            <a href="#FAQ"><li className="py-2 hover:bg-[white] hover:text-[#0779e4]" onClick={toggleSidebar}>FAQ</li></a>
            <a href="https://quantumamor.com/signup/" target="_blank" rel="noreferrer"><li className="py-2 hover:bg-[white] hover:text-[#0779e4]" onClick={toggleSidebar}>Sign-Up</li></a>
            <a href="https://quantumamor.com/signup/login"><li className="py-2 hover:bg-[white] hover:text-[#0779e4]" onClick={toggleSidebar}>Login</li></a>
          </ul>
        </div>
        <button
          className="absolute top-2 right-2 text-white"
          onClick={toggleSidebar}
        >
          <FiX size={24} />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
