
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import AccountReview from "./hooks/AccountReview";
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import { BsCheck2All } from "react-icons/bs";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const Social = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0fc3c3'
    }
  return (
   <>
   <Progress/>
     <div className="box1 overflow-hidden">
            <section class="Account">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit 
                relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                    <motion.div className="flex xl:pt-5 pt-10"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                       <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                       <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                    </motion.div>

                    <motion.div className="h-fit"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                        <p className=" font-bold xl:text-[3rem] text-[2rem]">
                           Account Activation/Unlock
                        </p>
                    </motion.div>
                </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        
        <div className='bg-gray-200'>
            <div className='mx-auto overflow-hidden  md:max-w-7xl'>
            <div className='grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 xl:pt-[5%] pt-2'>
                    <div className='bg-reen-600 h-fit'>
                        <div className='pr-2'>
                            <p>
                            Enduring the inconvenience of losing access to crucial systems can disrupt our daily routines and hinder our progress. In our esteemed organization, we recognize the urgency and importance of this issue, motivating us to prioritize our exceptional QuantumAmor service. Whether you require access to your personal profiles for legitimate reasons or assistance in reclaiming devices within legal boundaries, our dedicated team is prepared to provide unwavering support throughout the entire process. With a robust arsenal of resources and cutting-edge tools at our disposal, we possess the expertise to overcome even the most intricate account challenges.
                            </p> <br />
                            <p>
                            Rest assured, no platform presents a puzzle too daunting for us to tackle. With our profound proficiency and unwavering commitment to excellence, we adeptly navigate obstacles, ensuring swift and reliable access to your critical profiles. Don't allow account obstacles to impede your progress. Place your trust in our capabilities and empower yourself to regain control over your digital domain.
                            </p>
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit xl:pt-2 pt-1'>
                        <div className='flex justify-center'>
                            <img src="https://nextdoorsec.com/wp-content/uploads/2023/02/How-much-Does-it-Cost-to-Hire-a-Hacker.png" 
                            alt="" className='rounded-md'/>
                        </div>
                    </div>
            </div>
            </div>
        </div>

        <div className='bg-[white] h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl text-black'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on  Account <span className='top__b'>Unlock/Activation</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Deactivated Account</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Recliam Funds From Suspended or Locked Bank Account</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Suspended Social Media Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation Of suspended Social Media Account</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Locked Crypto wallet</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Locked Crypto wallet</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation Of suspended Social Media Account</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#5045E4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-white"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        For reliable account unlock and recovery, entrust QuantumAmor. With our expertise, individualized support, and platform policy acumen, we skillfully navigate the path to assist you in regaining access to your valuable accounts. Choose QuantumAmor Experts as your steadfast companion in this crucial journey.
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Account Recovery Expertise:</span>  We deliver tailored, hands-on assistance to tackle your unique challenges in account unlock and recovery. Our dedicated team collaborates closely with you, ensuring a seamless and efficient recovery process.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Versatile Platform Mastery:</span> From Instagram, Facebook, Twitter, TikTok, to various other social platforms, our proficiency spans a wide spectrum. We skillfully navigate the intricacies of diverse platforms, ensuring successful retrieval.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Diverse Platform Mastery:</span> From Instagram, Facebook, Twitter, TikTok, to various other social platforms, our proficiency spans a wide spectrum. We skillfully navigate the intricacies of diverse platforms, ensuring successful retrieval.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#5045E4]'/>
                            </div>
                            <p className='pt-2 pl-5'> 
                                <span className='font-bold'>Platform Policy Insight</span>   QuantumAmor Experts boast a deep understanding of various platform policies, streamlining the process and enabling us to unlock and recover your accounts with utmost efficiency and security.
                               
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
            </div>



                <div className='mx-auto overflow-hidden  md:max-w-7xl text-[black]'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Review on Acoount   <span className='top__b'>Unlock/Activation</span> </span></p>
                    </div>
                    <AccountReview/>
                </div>
                </div>


                <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>
  

        <Footer/>

   </>
  )
}

export default Social;