import { Route, Routes, } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Crypto from "./Components/Crypto";
import Romance from "./Components/Romance";
import Blackmail from "./Components/Blackmail";
import Social from "./Components/Social";
import PhoneUnlock from "./Components/PhoneUnlock";
import AccountUnlock from "./Components/AccountUnlock";
import About from "./Components/About";
import Contact from "./Components/Contact";
function App() {
  return (
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/Navbar' element={<Navbar/>}/>
      <Route path='/Crypto' element={<Crypto/>}/>
      <Route path='/Romance' element={<Romance/>}/>
      <Route path="/Blackmail" element={<Blackmail/>}/>
      <Route path='/Social' element={<Social/>}/>
      <Route path='/PhoneUnlock' element={<PhoneUnlock/>}/>
      <Route path='/AccountUnlock' element={<AccountUnlock/>}/>
      <Route path='/About' element={<About/>}/>
      <Route path='/Contact' element={<Contact/>}/>
    </Routes>
  );
}

export default App;
