import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'purple',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} 
      slidesPerGroup={1}
      breakpoints={{
      
        640: {
          slidesPerView: 2, 
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, 
          slidesPerGroup: 1,
        },
      
        1024: {
          slidesPerView: 3, 
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiZTMuJq3C2g2u8H8QLwmkN4G0ZI0WteEHUQ&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Jack Holgman</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[black] text-center py-[8%] px-2'>
          My Facebook account was hacked, and the person was using it to ask my friend list for money, which is embarrassing to me. I was referred to QuantumAmor, and this group of experts restored my Facebook account without wasting any time. Thanks to this team of experts! 
        </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-2'>Minnesota, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://www.southwalesargus.co.uk/resources/images/8805262/?type=og-image' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Hillary Nicholas</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#000] text-center py-[9%] px-2'>
          For a long time, my Instagram account was disabled, and despite my efforts, I couldn't recover it. Then, I came across a recommendation for QuantumAmor. They successfully restored my account. I am incredibly grateful for encountering this team of experts!
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>Tampa, USA  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d1bvpoagx8hqbg.cloudfront.net/259/9a273003b6219a1f3e6556dc69911d0c.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Mavie Isidro</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#000] text-center py-[3%] px-2'>
            For a while now, my husband has been acting weird—coming home late, making secret calls. I wanted to know what he's hiding, so I discovered QuantumAmor. I shared what was going on, and this team provided me with remote access to his phone and Text Messages . I finally caught my husband cheating, thanks to this team for saving me from fake love.
            </p>

          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black]'>illinois, USA</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOZywcue7cYaA7MJlAZUzkMyW1VyH44Bg7OQ&usqp=CAU'alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Szalay Márk</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center py-[2%] px-2'>
            I had suspicions about my girlfriend cheating on me, but I needed proof before confronting her about her unfaithfulness. Then, I saw recommendations about QuantumAmor. I explained what was going on, and this team provided me with remote access to all her social media platforms. I discovered she's not just cheating on me with one man, but with five of them. Thanks to this team for saving me.
            </p>

          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[2px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-[black] pt-[2px]'>Arizona, USA </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#efefef] serv__box'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src="https://i.pinimg.com/736x/54/ac/b1/54acb104eac2f54b27ebc2fd070f5942.jpg" alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Alexa Cat Evans</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#000] text-center py-[1%] px-2'>
            Growing uneasy with the mysterious behavior of my business partner, I sensed something was amiss. Late-night meetings, secretive conversations – it all seemed off. Wanting clarity, I stumbled upon QuantumAmor After explaining my concerns, this remarkable team facilitated remote access to our shared business files. Thanks to them, financial discrepancies and hidden dealings unfolded before my eyes, allowing me to uncover the truth and safeguard my business.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-black'>Egland, UK</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
